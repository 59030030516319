import model from './model';
import type { HeaderWidgetProps } from '../../types/menusTypes';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { HeaderController } from './headerController';

export default model.createController(({ $bindAll, $w, $widget, flowAPI }) => {
  const { translations, controllerConfig, environment, formatAddress } = flowAPI;
  const t = translations.t as TFunction;
  const isMemberLoggedIn = !!controllerConfig.wixCodeApi.user.currentUser.loggedIn;

  const headerController = new HeaderController(
    $bindAll,
    $w,
    t,
    formatAddress,
    controllerConfig.wixCodeApi.site,
    isMemberLoggedIn,
    environment.isSSR
  );

  headerController.init();

  const shouldInitHeaderTexts = (props: { data: HeaderWidgetProps }) => {
    const { headerTitle, headerDescription }: HeaderWidgetProps = props.data;
    return headerTitle || headerDescription;
  };

  $widget.onPropsChanged((_, nextProps) => {
    if (shouldInitHeaderTexts(nextProps)) {
      headerController.initHeaderTexts({ ...nextProps.data, t });
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      headerController.initHeaderTexts({ ...$widget.props.data, t });
    },
    exports: {},
  };
});
