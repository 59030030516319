import type { TFunction } from '@wix/yoshi-flow-editor';
import { ItemModalController } from './itemModalController';
import model from './model';
import { registerToUrlChange } from 'root/utils/navigationUtils';
import { PopulatedMenuClient } from 'root/api/PopulatedMenuClient';
import type { Item, PopulatedMenu } from 'root/types/menusTypes';
import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from 'root/api/consts';
import { PriceFormattingConverter } from '@wix/restaurants-olo-operations-client-commons';
import { getSiteCurrency } from 'root/utils/currency';
import { getPageOperationId } from 'root/utils/pageOperationUtils';
import { PopulateMenuIdsByOperationClient } from 'root/api/PopulateMenuIdsByOperationClient';
import { SPECS } from 'root/appConsts/experiments';
import { convertToRepeaterModifierGroups } from 'root/utils/itemModalControllerUtils';
import { getTimezoneOffset } from 'root/api/utils/utils';

export default model.createController(({ $w, $bindAll, $widget, flowAPI }) => {
  const {
    controllerConfig,
    translations,
    experiments,
    reportError,
    environment,
    httpClient,
    errorMonitor,
  } = flowAPI;

  const { wixCodeApi } = controllerConfig;
  const window = wixCodeApi.window;
  const { isEditor, isPreview } = environment;
  const lightboxContext = window.lightbox.getContext() || {};
  const { data = {}, onModalOpen, closeModal } = lightboxContext;
  const t = translations.t as TFunction;
  const timezone = wixCodeApi.site.timezone || DEFAULT_TIMEZONE;
  const locale = wixCodeApi.site.regionalSettings || DEFAULT_LOCALE;
  const priceFormatter = PriceFormattingConverter.createPriceFormatter(
    locale,
    getSiteCurrency(flowAPI)
  );
  const isMemberLoggedIn = !!wixCodeApi.user.currentUser.loggedIn;

  const itemModalController = new ItemModalController(
    $bindAll,
    $w,
    t,
    priceFormatter,
    window,
    isEditor,
    experiments,
    isMemberLoggedIn
  );

  const getFirstItem = (menus: PopulatedMenu[]): Item | undefined => {
    let firstItem;
    const menuWithItems = menus.find((menu) =>
      menu.sections.some((section) => section.items?.length)
    );
    if (menuWithItems) {
      const sectionWithItems = menuWithItems.sections?.find((section) => section.items?.length);
      firstItem = sectionWithItems?.items?.[0];
    }

    return firstItem;
  };

  const getItem = async () => {
    const { item, canAcceptOrders, fedopsLogger } = data ?? {};
    if ((isEditor || isPreview) && !item) {
      try {
        const { metaSiteId = '' } = flowAPI.controllerConfig.platformAPIs.bi || {};
        const getMenusByOperation =
          experiments.enabled(SPECS.multiPages) && experiments.enabled(SPECS.getMenusByOperation);
        const pageOperationIdPromise = getMenusByOperation
          ? getPageOperationId(flowAPI.controllerConfig.wixCodeApi.site, flowAPI.errorMonitor)
          : undefined;

        const menuIdsByOperation = PopulateMenuIdsByOperationClient({
          httpClient,
          experiments,
          sentry: errorMonitor,
          fedopsLogger,
          timezoneOffset: getTimezoneOffset(timezone),
        }).getAll(pageOperationIdPromise);

        const menus = await PopulatedMenuClient({
          httpClient,
          experiments,
          msid: metaSiteId,
          currency: getSiteCurrency(flowAPI),
        }).getAll(menuIdsByOperation);
        return { item: menus?.length ? getFirstItem(menus) : undefined, canAcceptOrders: true };
      } catch (err) {
        reportError(new Error(`Editor mode - fetch data for item modal error ${err}`));
      }
    }
    return { item, canAcceptOrders };
  };

  const processedItemDataPromise = getItem().then(async ({ item, canAcceptOrders }) => {
    const modifierGroups = convertToRepeaterModifierGroups(item.modifierGroups ?? []);
    return { item, canAcceptOrders, modifierGroups };
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      registerToUrlChange(controllerConfig.wixCodeApi, closeModal, window);

      try {
        const { item, canAcceptOrders, modifierGroups } = await processedItemDataPromise;

        itemModalController.init(
          { ...data, item, canAcceptOrders },
          onModalOpen,
          closeModal,
          modifierGroups
        );
      } catch (e) {
        console.error(new Error(`fetch data failed ${e} ${isEditor} ${isPreview}`));
      }
    },
    exports: {},
  };
});
