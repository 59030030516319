import type model from './model';
import { OLO_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { I$W, TFunction } from '@wix/yoshi-flow-editor';
import type { OLOWidgetState } from '../../types/businessTypes';
import { state } from '../../states/RootState';
import type { ControllerParams } from 'root/types/widgets';
import { menusState } from 'root/states/MenusState';

type BindAll = ControllerParams<typeof model>['$bindAll'];

export class OLOController {
  constructor(private $bindAll: BindAll, private $w: I$W, private t: TFunction) {
    state.pubsub.subscribe('onFetchFailed', this.switchState);
  }

  setNavigationMenu() {
    this.$bindAll({
      [OLO_WIDGET_COMPONENT_IDS.navigationMenu]: {
        data: () => ({ menus: menusState.sortedMenusDto }),
        collapsed: () => !menusState.hasAtLeastOneMenuWithSections,
      },
    });
  }

  switchState = ({ oloState }: { oloState: OLOWidgetState }) => {
    const multiStateBox = this.$w(OLO_WIDGET_COMPONENT_IDS.oloMultiStateBox);
    multiStateBox.changeState(oloState);

    this.$bindAll({
      [OLO_WIDGET_COMPONENT_IDS.errorStateTitle]: {
        text: () => this.t('menu_olo.errorState.title'),
      },
      [OLO_WIDGET_COMPONENT_IDS.errorStateSubtitle]: {
        text: () => this.t('menu_olo.errorState.subTitle'),
      },
    });
  };
}
