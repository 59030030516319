import type {
  FulfillmentTimeSlot,
  FulfillmentDetails,
} from '@wix/ambassador-restaurants-operations-v1-operation/types';
import { FulfillmentTimeType } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import type { TimeSlot } from 'root/types/businessTypes';
import { DateTime } from 'luxon';
import { DispatchType } from 'root/types/businessTypes';
import { createTimeSlotId } from './utils';

// returns true if all objects in the array have the same value for the given key
export function hasSameByField<T>(field: keyof T, arr: T[]): boolean {
  const [first, ...rest] = arr;
  return rest.every((item) => item[field] === first[field]);
}

// returns an array of objects with the minimum value for the given key
export function getMinValueObjects<T>(key: keyof T, arr: T[]): T[] {
  return arr.slice(1).reduce((min, item) => {
    if (Number(item[key]) < Number(min[0][key])) {
      return [item];
    } else if (Number(item[key]) === Number(min[0][key])) {
      return [...min, item];
    }
    return min;
  }, (arr[0] ? [arr[0]] : []) as T[]);
}

// returns true if all objects in the array have the same fulfillment time
export function hasSameTime(arr: FulfillmentDetails[]): boolean {
  return (
    hasSameByField('fulfillmentTimeType', arr) &&
    (arr[0]?.fulfillmentTimeType === FulfillmentTimeType.MAX_TIME
      ? hasSameByField('maxTimeOptions', arr)
      : arr.every((details) => {
          const { durationRangeOptions } = details;
          return (
            durationRangeOptions?.maxDuration === arr[0].durationRangeOptions?.maxDuration &&
            durationRangeOptions?.minDuration === arr[0].durationRangeOptions?.minDuration
          );
        }))
  );
}

// returns the fastest fulfillment time options
export function getFastestTimeOptions(arr: FulfillmentDetails[]): FulfillmentDetails[] {
  return arr.slice(1).reduce((minArr, item) => {
    const { durationRangeOptions, maxTimeOptions, fulfillmentTimeType } = item;
    const min = minArr[0];
    const minTime =
      min.fulfillmentTimeType === FulfillmentTimeType.DURATION_RANGE
        ? Number(min.durationRangeOptions!.minDuration)
        : Number(min.maxTimeOptions);
    const currTime =
      fulfillmentTimeType === FulfillmentTimeType.DURATION_RANGE
        ? Number(durationRangeOptions!.minDuration)
        : Number(maxTimeOptions);
    if (currTime < minTime) {
      return [item];
    } else if (currTime === minTime) {
      return [...minArr, item];
    }
    return minArr;
  }, (arr[0] ? [arr[0]] : []) as FulfillmentDetails[]);
}

// returns the slowest fulfillment time option
export function getSlowestTimeOption(arr: FulfillmentDetails[]): FulfillmentDetails {
  return arr.reduce((max, item) => {
    const { durationRangeOptions, maxTimeOptions, fulfillmentTimeType } = item;
    let maxTime;
    if (max.fulfillmentTimeType === FulfillmentTimeType.DURATION_RANGE) {
      maxTime = Number(max.durationRangeOptions?.maxDuration || 0);
    } else {
      maxTime = Number(max.maxTimeOptions || 0);
    }
    if (durationRangeOptions && fulfillmentTimeType === FulfillmentTimeType.DURATION_RANGE) {
      return Number(durationRangeOptions.maxDuration) > maxTime ? item : max;
    } else {
      return Number(maxTimeOptions) > maxTime ? item : max;
    }
  }, {} as FulfillmentDetails);
}

// creates a time range object from an array of fulfillment details
export const createTimeRange = (arr: FulfillmentDetails[]) => {
  const fastestOption = getFastestTimeOptions(arr)[0];
  const slowestOption = getSlowestTimeOption(arr);
  const minDuration =
    fastestOption.durationRangeOptions?.minDuration ?? fastestOption.maxTimeOptions;
  const maxDuration =
    slowestOption.durationRangeOptions?.maxDuration ?? slowestOption.maxTimeOptions;
  if (minDuration === maxDuration) {
    return { maxTimeOptions: minDuration };
  } else {
    return { durationRangeOptions: { minDuration, maxDuration } };
  }
};

// handles the case where all fulfillment details have the same min order price
export const resolveSameMinOrderPriceOption = (fulfillmentDetails: FulfillmentDetails[]) => {
  // filtering fulfillment details by order of precedent: fee, time, free delivery price threshold
  const cheapestFulfillmentDetails = getMinValueObjects('fee', fulfillmentDetails);
  const fastestFulfillmentDetails = getFastestTimeOptions(cheapestFulfillmentDetails);
  return getMinValueObjects('freeFulfillmentPriceThreshold', fastestFulfillmentDetails)[0];
};

// handles the case where fulfillment details have different min order prices
export const resolveDifferentMinOrderPriceOption = (fulfillmentDetails: FulfillmentDetails[]) => {
  const hasSameFee = hasSameByField('fee', fulfillmentDetails);

  if (hasSameFee && hasSameTime(fulfillmentDetails)) {
    // if fee and time are the same, return the object with the min order price with the min free delivery price threshold
    return getMinValueObjects(
      'freeFulfillmentPriceThreshold',
      getMinValueObjects('minOrderPrice', fulfillmentDetails)
    )[0];
  } else {
    // if the fee is the same and time is different, return the fee
    const fee = hasSameFee ? fulfillmentDetails[0].fee : undefined;
    // if free delivery price threshold is the same, return free delivery price threshold
    const freeFulfillmentPriceThreshold = hasSameByField(
      'freeFulfillmentPriceThreshold',
      fulfillmentDetails
    )
      ? fulfillmentDetails[0].freeFulfillmentPriceThreshold
      : undefined;
    // create a time range object from all the fulfillments
    return {
      fee,
      ...createTimeRange(fulfillmentDetails),
      freeFulfillmentPriceThreshold,
    };
  }
};

export const resolveFulfillmentDetails = (
  fulfillmentDetails: FulfillmentDetails[]
): FulfillmentDetails => {
  if (hasSameByField('minOrderPrice', fulfillmentDetails)) {
    return resolveSameMinOrderPriceOption(fulfillmentDetails);
  } else {
    return resolveDifferentMinOrderPriceOption(fulfillmentDetails);
  }
};

export const processFulfillmentTimeSlot = (fulfillmentTimeSlot: FulfillmentTimeSlot): TimeSlot => {
  const { startTime, endTime, fulfillmentDetails, fulfilmentType, startsNow } = fulfillmentTimeSlot;

  return {
    id: createTimeSlotId(startTime!, endTime!),
    startTime: DateTime.fromJSDate(startTime!),
    endTime: DateTime.fromJSDate(endTime!),
    dispatchType: fulfilmentType === 'DELIVERY' ? DispatchType.DELIVERY : DispatchType.PICKUP,
    startsNow,
    fulfillmentDetails: resolveFulfillmentDetails(fulfillmentDetails!),
  };
};

export const mergeASAPTimeSlots = (fulfillmentTimeSlots: FulfillmentTimeSlot[]) => {
  const asapTimeSlots = fulfillmentTimeSlots.filter((slot) => slot.startsNow);
  if (asapTimeSlots.length <= 1) {
    return fulfillmentTimeSlots;
  }
  const fulfillmentDetails = asapTimeSlots.map((slot) => slot.fulfillmentDetails ?? []).flat();
  const mergedTimeSlot: FulfillmentTimeSlot = {
    ...asapTimeSlots[asapTimeSlots.length - 1],
    fulfillmentDetails,
  };
  const timeSlots = fulfillmentTimeSlots.filter((slot) => !slot.startsNow);
  timeSlots.push(mergedTimeSlot);
  return timeSlots;
};
