import type { FulfillmentsClient } from 'root/api/fulfillmentsClient';
import { DateTime } from 'luxon';
import type { TimeSlot, Address } from 'root/types/businessTypes';
import { DispatchType } from 'root/types/businessTypes';
import { dispatchState } from '../states/DispatchState';
import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getMonitoredApiCall, type ReportError } from 'root/api/utils/getMonitoredApiCall';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { DEFAULT_TIMEZONE } from 'root/api/consts';
import { sortDates } from 'root/utils/dateTimeUtils';

type AvailableDates = Record<DispatchType, Map<string, DateTime[]>>;

type TimeSlots = Record<DispatchType, Map<string, TimeSlot[]>>;

export const getDateRangeKey = (start: Date, end: Date) =>
  `${DateTime.fromJSDate(start).startOf('day').toString()}-${DateTime.fromJSDate(end)
    .startOf('day')
    .toString()}`;

export class TimeSlotRepository {
  private availableDates: AvailableDates = {
    [DispatchType.PICKUP]: new Map<string, DateTime[]>(),
    [DispatchType.DELIVERY]: new Map<string, DateTime[]>(),
  };

  private timeSlots: TimeSlots = {
    [DispatchType.PICKUP]: new Map<string, TimeSlot[]>(),
    [DispatchType.DELIVERY]: new Map<string, TimeSlot[]>(),
  };

  private reportError?: ReportError;
  private errorMonitor?: ErrorMonitor;
  private timezone: string;

  constructor(
    private fulfillmentsClient: FulfillmentsClient,
    private fedopsLogger?: FedopsLogger,
    flowAPI?: PlatformControllerFlowAPI
  ) {
    const { errorMonitor, reportError } = flowAPI ?? {};
    this.reportError = reportError;
    this.errorMonitor = errorMonitor;
    this.timezone = flowAPI?.controllerConfig.wixCodeApi.site.timezone ?? DEFAULT_TIMEZONE;
  }

  private get fedopsInteractions() {
    if (this.fedopsLogger) {
      return {
        fetchAvailableDatesInRange: {
          start: this.fedopsLogger.fetchAvailableDatesInRangeStarted,
          end: this.fedopsLogger.fetchAvailableDatesInRangeEnded,
        },
        fetchAvailableTimeSlotsForDate: {
          start: this.fedopsLogger.fetchAvailableTimeSlotsForDateStarted,
          end: this.fedopsLogger.fetchAvailableTimeSlotsForDateEnded,
        },
        fetchFirstAvailableTimeSlot: {
          start: this.fedopsLogger.fetchFirstAvailableTimeSlotStarted,
          end: this.fedopsLogger.fetchFirstAvailableTimeSlotEnded,
        },
      };
    }
    return undefined;
  }

  private get commonArgs() {
    const deliveryAddress =
      dispatchState.selectedDispatchType === DispatchType.DELIVERY
        ? dispatchState.dispatchInfo.address
        : undefined;
    return {
      dispatchType: dispatchState.selectedDispatchType,
      deliveryAddress,
    };
  }

  private monitorAPICall<T>(
    callback: () => Promise<T>,
    fedops?: { start: Function; end: Function }
  ) {
    return getMonitoredApiCall({
      callback,
      fedops,
      reportError: this.reportError,
      sentry: this.errorMonitor,
    });
  }

  async getAvailableDates({ from, until }: { from: Date; until: Date }) {
    const key = getDateRangeKey(from, until);
    const availableDatesByDispatchType = this.availableDates[dispatchState.selectedDispatchType];

    if (!availableDatesByDispatchType.get(key)) {
      const fetchAvailableDatesInRange = () =>
        this.fulfillmentsClient.fetchAvailableDatesInRange({
          from,
          until,
          timezone: this.timezone,

          ...this.commonArgs,
        });
      const { data: dates = [] } = await this.monitorAPICall(
        fetchAvailableDatesInRange,
        this.fedopsInteractions?.fetchAvailableDatesInRange
      );
      const sortedDates = sortDates(dates);
      availableDatesByDispatchType.set(key, sortedDates);
    }

    return availableDatesByDispatchType.get(key) ?? [];
  }

  async getTimeSlots({ date }: { date: Date }) {
    const timeSlotsByDispatchType = this.timeSlots[dispatchState.selectedDispatchType];
    const key = DateTime.fromJSDate(date).startOf('day').toString();
    if (!timeSlotsByDispatchType.get(key)) {
      const fetchAvailableTimeSlotsForDate = () =>
        this.fulfillmentsClient.fetchAvailableTimeSlotsForDate({
          date,
          ...this.commonArgs,
        });
      const { data: timeSlots = [] } = await this.monitorAPICall(
        fetchAvailableTimeSlotsForDate,
        this.fedopsInteractions?.fetchAvailableTimeSlotsForDate
      );
      timeSlotsByDispatchType.set(key, timeSlots.reverse());
    }
    return timeSlotsByDispatchType.get(key) ?? [];
  }

  async getFirstAvailableTimeSlot(address: Address) {
    const { data: timeSlots = [] } = await this.monitorAPICall(
      () => this.fulfillmentsClient.fetchFirstAvailableTimeSlot(address),
      this.fedopsInteractions?.fetchFirstAvailableTimeSlot
    );
    return timeSlots.filter((slot) => slot.dispatchType === dispatchState.selectedDispatchType)[0];
  }

  resetCache(dispatchType: DispatchType) {
    this.timeSlots[dispatchType] = new Map<string, TimeSlot[]>();
    this.availableDates[dispatchType] = new Map<string, DateTime[]>();
  }
}
