import model from './model';
import { MenusController } from './menusController';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from 'root/api/consts';

export default model.createController(({ $w, $bindAll, $widget, flowAPI }) => {
  const { translations, environment, experiments, controllerConfig } = flowAPI;
  const t = translations.t as TFunction;
  const { isEditor } = environment;
  const { wixCodeApi } = controllerConfig;
  const { timezone = DEFAULT_TIMEZONE, regionalSettings } = wixCodeApi.site;
  const locale = regionalSettings || DEFAULT_LOCALE;
  const isMemberLoggedIn = !!wixCodeApi.user.currentUser.loggedIn;

  const menusController = new MenusController(
    $bindAll,
    $w,
    t,
    experiments,
    isEditor,
    timezone,
    locale,
    isMemberLoggedIn
  );

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      menusController.init();
    },
    exports: {},
  };
});
