import type model from './model';
import type { TFunction, I$W, Experiments } from '@wix/yoshi-flow-editor';
import { state } from '../../states/RootState';
import { LABELS_LIMIT } from '../../api/consts';
import {
  DISHES_WIDGET_COMPONENT_IDS,
  UNAVAILABLE_DISHES_COMPONENT_IDS,
} from '../../appConsts/blocksIds';
import type { Item } from '../../types/menusTypes';
import { buildImgSrc, getAltText, isItemValidInCart } from './utils';
import { DISHES_WIDGET_STATES, DISH_STATES } from '../../types/businessTypes';
import type { DishesWidgetState } from '../../types/businessTypes';
import type { ItemData } from '../../types/item';
import { getVariantWithMinimalPrice } from '../../utils/priceVariantsUtils';
import { ADD_TO_CART_ERRORS } from '../../services/cartService';
import { LiveSiteClickFulfillmentOrigin } from '@wix/restaurants-bi';
import { dispatchState } from 'root/states/DispatchState';
import type { ControllerParams } from 'root/types/widgets';
import { SPECS } from 'root/appConsts/experiments';
import { getAreNotEnoughModifiersOfMandatoryModifierGroupInStock } from 'root/utils/itemModalControllerUtils';
import { menusState } from 'root/states/MenusState';
import { openDispatchModal } from 'root/utils/utils';
import { availabilityStatusKeys } from 'root/availabilityStatusKeys';
import { getAvailabilityStatusProps } from 'root/utils/menusUtils';

type BindAll = ControllerParams<typeof model>['$bindAll'];

export const getDishesState = (items: Item[], isTruncated: boolean): DishesWidgetState => {
  let dishState = items.length > 0 ? DISHES_WIDGET_STATES.dishes : DISHES_WIDGET_STATES.dishesEmpty;
  if (isTruncated && items.length === 0) {
    dishState = DISHES_WIDGET_STATES.loading;
  }
  return dishState;
};

export class DishesController {
  constructor(
    private $bindAll: BindAll,
    private $w: I$W,
    private t: TFunction,
    private isMobile: boolean,
    private experiments: Experiments,
    private timezone: string,
    private locale: string,
    private isMemberLoggedIn?: boolean
  ) {}

  async openDishModal(itemData: ItemData, menuId: string, sectionId: string) {
    const menu = menusState.getMenu(menuId);
    const { isMenuOfItemAvailable, hasNextAvailability, text, shouldCollapseAvailabilityStatus } =
      getAvailabilityStatusProps({
        menu,
        locale: this.locale,
        timezone: this.timezone,
        t: this.t,
        keys: availabilityStatusKeys.itemModal,
      });

    const dishModalRes = await state.ModalService?.openDishModal({
      item: itemData,
      cartService: state.CartService,
      biReporterService: state.biReporterService,
      operationId: state.operation?.id,
      canAcceptOrders: dispatchState.hasAvailableDispatches,
      menuId,
      sectionId,
      fedopsLogger: state.fedopsLogger,
      openItemModalByQueryParams: this.experiments.enabled(SPECS.openItemModalByQueryParams),
      availabilityStatusProps: {
        isMenuOfItemAvailable,
        text,
        shouldCollapseAvailabilityStatus,
        hasNextAvailability,
        dispatchType: dispatchState.selectedDispatchType,
      },
      openDispatchModal: async () => this.openDispatchModal(itemData, menuId, sectionId),
    });

    const { data: dataPromise, additionaldata } = dishModalRes ?? {};
    const cartLineItemsKey = `${menuId}_${sectionId}_${itemData._id}`;
    const cartLineItems = state.cartLineItems.get(cartLineItemsKey) ?? [];
    additionaldata && state.cartLineItems.set(cartLineItemsKey, [...cartLineItems, additionaldata]);

    const data = await dataPromise;

    if (
      data?.error === ADD_TO_CART_ERRORS.MIXED_CART ||
      data?.error === ADD_TO_CART_ERRORS.MIXED_CART_OPERATION
    ) {
      const content =
        data?.error === ADD_TO_CART_ERRORS.MIXED_CART
          ? this.t('cart.mixed-vertical-cart-error.content')
          : this.t('cart.mixed-cart-by-operation-error.content');

      state.ModalService?.openErrorModal({
        title: this.t('cart.mixed-vertical-cart-error.title'),
        content,
        closeButtonLabel: this.t('cart.mixed-vertical-cart-error.button'),
      });
      state.cartLineItems.set(cartLineItemsKey, cartLineItems);
      return;
    }

    if (data && !data.cartItem) {
      state.cartLineItems.set(cartLineItemsKey, cartLineItems);
      state.ModalService?.openErrorModal({
        title: this.t('addToCart.error-modal.general-error.title'),
        content: this.t('addToCart.error-modal.general-error.content'),
        closeButtonLabel: this.t('addToCart.error-modal.general-error.button'),
      });
    }
  }

  async openDispatchModal(itemData: ItemData, menuId: string, sectionId: string) {
    const self = this;
    openDispatchModal({
      onSave: async ({ dispatchType, dispatchInfo }) => {
        dispatchState.update(dispatchType, dispatchInfo);
        state.CartService?.setShippingDetails(dispatchState.getShippingDetails());
        await menusState.updateAvailabilityStatus(state.operation?.id, dispatchInfo, dispatchType);
        self.openDishModal(itemData, menuId, sectionId);
      },
      rootState: state,
      dispatchState: dispatchState.state,
    });
  }

  init(items: Item[], isEditor: boolean, menuId: string, sectionId: string) {
    const isMenuStatusEnabled = this.experiments.enabled(SPECS.menuStatus);

    if (items.length > 0) {
      if (isEditor) {
        this.restoreItemCounterInEditor(menuId, sectionId);
      }

      const onItemInteraction = (itemData: ItemData, amount?: string | number) => {
        const isItemInStock = !!itemData.orderSettings?.inStock;
        state.biReporterService?.reportOloLiveSiteClickOnItemBiEvent({
          itemName: itemData.name,
          itemId: itemData._id,
          menuId,
          sectionId,
          minItemPrice: Number(amount || 0),
          operationId: state.operation?.id,
          isMenuItemAvailable: menusState.getMenu(menuId)?.isAvailable ?? true,
          isItemInStock,
        });
        const shouldOpenDishModal =
          !!dispatchState.dispatchInfo.address || !dispatchState.hasAvailableDispatches;
        if (shouldOpenDishModal) {
          this.openDishModal(itemData, menuId, sectionId);
        } else {
          state.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
            origin: LiveSiteClickFulfillmentOrigin.CLICK_ITEM_NO_ADDRESS,
            dispatchType: dispatchState.selectedDispatchType,
            isMemberLoggedIn: this.isMemberLoggedIn,
          });
          this.openDispatchModal(itemData, menuId, sectionId);
        }
      };

      this.$bindAll({
        [DISHES_WIDGET_COMPONENT_IDS.repeaterItems]: {
          data: () =>
            items.map(({ id, ...rest }) => ({
              _id: id,
              ...rest,
            })),
          // @ts-expect-error
          item: (itemData: ItemData, bindItem) => {
            const areNotEnoughModifiersOfMandatoryModifierGroupInStock =
              getAreNotEnoughModifiersOfMandatoryModifierGroupInStock(itemData.modifierGroups);
            const isInStockItem =
              (itemData.orderSettings?.inStock &&
                !areNotEnoughModifiersOfMandatoryModifierGroupInStock) ??
              true;
            const amount = itemData.priceVariants
              ? getVariantWithMinimalPrice(itemData.priceVariants.variants || [])?.priceInfo.price
              : itemData.price.amount;

            const formattedPrice = state.priceFormatter(Number(amount));

            [DISHES_WIDGET_COMPONENT_IDS, UNAVAILABLE_DISHES_COMPONENT_IDS].forEach(
              (componentIds) => {
                bindItem(componentIds.itemContainer, {
                  onClick: () => onItemInteraction(itemData, amount),
                });
                bindItem(componentIds.itemTitle, {
                  text: () => itemData.name ?? '',
                  hidden: () => !itemData.name,
                  collapsed: () => this.isMobile && !itemData.name,
                });
                !isEditor &&
                  bindItem(componentIds.itemTitleWrapper, {
                    hidden: () => !itemData.name,
                    collapsed: () => this.isMobile && !itemData.name,
                    onKeyPress: (e: KeyboardEvent) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        onItemInteraction(itemData, amount);
                      }
                    },
                    accessibility: {
                      ariaAttributes: {
                        haspopup: () => 'true',
                      },
                      role: () => 'button',
                      tabIndex: () => 0,
                    },
                  });

                bindItem(componentIds.itemDescription, {
                  text: () => itemData.description ?? '',
                  hidden: () => !itemData.description,
                  collapsed: () => this.isMobile && !itemData.description,
                });

                bindItem(componentIds.itemPrice, {
                  text: () => {
                    const menuState = menusState.getMenu(menuId);
                    const hasNextAvailableTime = !!menuState?.nextAvailableTimeslot;
                    const isMenuOfItemAvailable = menuState?.isAvailable ?? true;
                    const isItemUnavailable = isMenuStatusEnabled
                      ? !isMenuOfItemAvailable && !hasNextAvailableTime
                      : !isMenuOfItemAvailable;
                    return this.getItemPriceText(isInStockItem, isItemUnavailable, formattedPrice);
                  },
                });

                const imageProps = itemData.image?.url
                  ? {
                      src: () => (itemData.image && buildImgSrc(itemData.image)) ?? '',
                      alt: () => getAltText({ itemName: itemData.name, t: this.t }),
                    }
                  : {};

                bindItem(componentIds.itemImage, {
                  ...imageProps,
                  collapsed: () => !itemData.image?.url,
                });

                const hasLabels = itemData.labels?.length;

                bindItem(componentIds.labelContainer, {
                  hidden: () => !this.isMobile && !hasLabels,
                  collapsed: () => this.isMobile && !hasLabels,
                });

                const cartLineItemsKey = `${menuId}_${sectionId}_${itemData._id}`;

                hasLabels &&
                  this.initLabels(
                    itemData,
                    bindItem,
                    componentIds.label,
                    componentIds.additionalLabelsCounter
                  );
                bindItem(componentIds.itemCounter, {
                  collapsed: () => {
                    const cartItems = state.cartLineItems.get(cartLineItemsKey);
                    return !isItemValidInCart(cartItems);
                  },
                  hidden: () => {
                    const cartItems = state.cartLineItems.get(cartLineItemsKey);
                    return !isItemValidInCart(cartItems);
                  },
                });

                bindItem(componentIds.itemCounterValue, {
                  text: () => {
                    const cartItems = state.cartLineItems.get(cartLineItemsKey);
                    const value =
                      cartItems?.reduce(
                        (previousValue, currentValue) =>
                          previousValue + (currentValue?.quantity || 0),
                        0
                      ) || 0;
                    return value.toString();
                  },
                });
              }
            );

            bindItem(DISHES_WIDGET_COMPONENT_IDS.dishStateMultiStateBox, {
              currentState: () => {
                const menuState = menusState.getMenu(menuId);
                const hasNextAvailableTime = !!menuState?.nextAvailableTimeslot;
                const shouldShowAvailableState =
                  isInStockItem &&
                  ((menuState?.isAvailable ?? true) ||
                    (isMenuStatusEnabled && hasNextAvailableTime));
                return shouldShowAvailableState ? DISH_STATES.regular : DISH_STATES.unavailable;
              },
            });
          },
        },
      });
    } else {
      this.$bindAll({
        [DISHES_WIDGET_COMPONENT_IDS.dishEmptyStateTitle]: {
          text: () => this.t('menu_olo.emptyState.title'),
        },
        [DISHES_WIDGET_COMPONENT_IDS.dishEmptyStateSubtitle]: {
          text: () => this.t('menu_olo.emptyState.subTitle'),
        },
      });
    }
  }

  initLabels(
    itemData: ItemData,
    // @ts-expect-error
    bindItem,
    getLabelElement: (idx: number) => string,
    additionalLabelsCounterId: string
  ) {
    for (let i = 0; i < LABELS_LIMIT; i++) {
      const currentLabel = itemData.labels?.[i];
      const currentLabelElement = getLabelElement(i + 1);

      bindItem(currentLabelElement, {
        collapsed: () => !currentLabel,
      });

      if (currentLabel) {
        const iconSrc = currentLabel.icon?.url ? { src: () => currentLabel.icon?.url } : {};

        bindItem(currentLabelElement, {
          ...iconSrc,
          accessibility: {
            ariaAttributes: {
              label: () => currentLabel.name ?? '',
            },
          },
          alt: () => currentLabel.name ?? '',
          collapsed: () => !currentLabel?.icon?.url,
        });
      }
    }

    const numOfAdditionalLabels = itemData.labels.length - LABELS_LIMIT;
    const showLabelCounter = itemData.labels.length > LABELS_LIMIT;
    const PLUS_SIGN = '+';
    bindItem(additionalLabelsCounterId, {
      text: () => (showLabelCounter ? `${PLUS_SIGN}${numOfAdditionalLabels}` : ''),
      collapsed: () => !showLabelCounter,
      hidden: () => !showLabelCounter,
    });
  }

  switchState(dishState: DishesWidgetState) {
    const multiStateBox = this.$w(DISHES_WIDGET_COMPONENT_IDS.dishesWidgetMultiStateBox);
    multiStateBox.changeState(dishState);
  }

  getItemPriceText(isInStockItem: boolean, isItemUnavailable: boolean, formattedPrice: string) {
    let itemPriceText;
    if (isItemUnavailable) {
      itemPriceText = this.t('menu_olo.itemUnavailable.itemPrice', {
        price: formattedPrice,
      });
    } else if (!isInStockItem) {
      itemPriceText = this.t('menu_olo.OutOfStock.itemPrice', {
        price: formattedPrice,
      });
    } else {
      itemPriceText = formattedPrice;
    }

    return itemPriceText;
  }

  deleteItemCounterInEditor(menuId: string) {
    this.$w(DISHES_WIDGET_COMPONENT_IDS.repeaterItems).forEachItem(
      ($item: I$W, itemData: ItemData) => {
        const isInStockItem = itemData.orderSettings?.inStock ?? true;
        const isMenuOfItemAvailable = menusState.getMenu(menuId)?.isAvailable ?? true;
        const dishesWidgetIds =
          isInStockItem && isMenuOfItemAvailable
            ? DISHES_WIDGET_COMPONENT_IDS
            : UNAVAILABLE_DISHES_COMPONENT_IDS;
        $item(dishesWidgetIds.itemCounter).delete();
      }
    );
  }

  restoreItemCounterInEditor(menuId: string, sectionId: string) {
    this.$w(DISHES_WIDGET_COMPONENT_IDS.repeaterItems).forEachItem(
      ($item: I$W, itemData: ItemData) => {
        const isInStockItem = itemData.orderSettings?.inStock ?? true;
        const isMenuOfItemAvailable = menusState.getMenu(menuId)?.isAvailable ?? true;
        const dishesWidgetIds =
          isInStockItem && isMenuOfItemAvailable
            ? DISHES_WIDGET_COMPONENT_IDS
            : UNAVAILABLE_DISHES_COMPONENT_IDS;

        const shouldRestoreBadge = this.hasItemsInCart(menuId, sectionId, itemData._id);
        shouldRestoreBadge && $item(dishesWidgetIds.itemCounter).restore();
      }
    );
  }

  hasItemsInCart(menuId: string, sectionId: string, itemId: string) {
    const cartLineItemsKey = `${menuId}_${sectionId}_${itemId}`;
    const cartItems = state.cartLineItems.get(cartLineItemsKey);

    const hasItemInCart = isItemValidInCart(cartItems);
    return hasItemInCart;
  }
}
