import model from './model';
import { DishesController, getDishesState } from './dishesController';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { hasDataChanged } from '../../utils/utils';
import type { DishesWidgetProps } from '../../types/menusTypes';
import type { Item } from 'root/types/menusTypes';
import { runInAction, autorun } from 'mobx';
import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from 'root/api/consts';

export default model.createController(({ $w, $bindAll, $widget, flowAPI, initState }) => {
  const { controllerConfig, translations, environment, experiments } = flowAPI;
  const t = translations.t as TFunction;
  const { isMobile, isEditor } = environment;
  const { wixCodeApi } = controllerConfig;
  const isMemberLoggedIn = !!wixCodeApi.user.currentUser.loggedIn;
  const { timezone = DEFAULT_TIMEZONE, regionalSettings } = wixCodeApi.site;
  const locale = regionalSettings || DEFAULT_LOCALE;

  const dishesController = new DishesController(
    $bindAll,
    $w,
    t,
    isMobile,
    experiments,
    timezone,
    locale,
    isMemberLoggedIn
  );

  let prevItems: Item[] = [];

  const { state } = initState({
    dishesWidget: getDishesState($widget.props.data.items, $widget.props.data.isTruncated),
  });

  $widget.onPropsChanged((prevProps, nextProps) => {
    const { items, menuId, sectionId, isTruncated }: DishesWidgetProps = nextProps.data;

    const shouldInit = hasDataChanged({
      prevData: prevItems,
      nextData: items,
    });

    const dishState = getDishesState(items, isTruncated);

    if (shouldInit) {
      prevItems = items;
      dishesController.init(items, isEditor, menuId, sectionId);
    }

    if (dishState !== state.dishesWidget) {
      runInAction(() => {
        state.dishesWidget = dishState;
      });
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { items, menuId, sectionId }: DishesWidgetProps = $widget.props.data;
      if (isEditor && items.length > 0) {
        dishesController.deleteItemCounterInEditor(menuId);
      }
      const shouldInit = hasDataChanged({
        prevData: prevItems,
        nextData: items,
      });
      autorun(() => {
        dishesController.switchState(state.dishesWidget);
      });
      if (shouldInit) {
        prevItems = items;
        dishesController.init(items, isEditor, menuId, sectionId);
      }
    },
    exports: {},
  };
});
