import { makeAutoObservable, runInAction } from 'mobx';
import type { PopulatedMenu } from 'root/types/menusTypes';
import type { SectionList } from 'root/components/Navigation/utils';
import { initSectionsList } from 'root/components/Navigation/utils';
import { truncate } from 'root/utils/utils';
import { MAX_CHARS_SECTION_TAB } from 'root/appConsts/consts';

class NavigationMenuState {
  selectedMenuId = '';
  selectedSectionId = '';
  menus: PopulatedMenu[] = [];
  sectionScrollList: SectionList = {};
  constructor() {
    makeAutoObservable(this);
  }

  get menuMap() {
    return this.menus.reduce(
      (acc, menu) => ({ ...acc, [menu.id]: menu }),
      {} as Record<string, PopulatedMenu>
    );
  }

  setMenus(menus: PopulatedMenu[]) {
    runInAction(() => {
      this.menus = menus;
      this.setSelectedMenuId(menus[0]?.id);
      this.sectionScrollList = initSectionsList(menus);
    });
  }

  get hasMultipleMenus() {
    return this.menus.length > 1;
  }

  get selectedMenu() {
    return this.menuMap[this.selectedMenuId];
  }

  get sectionTabs() {
    return this.selectedMenu?.sections?.map((section) => {
      const label = truncate(section.name || '', MAX_CHARS_SECTION_TAB);
      return {
        label,
        id: section.id,
        selected: this.selectedSectionId === section.id,
      };
    });
  }

  setSelectedMenuId(menuId: string) {
    runInAction(() => {
      this.selectedMenuId = menuId;
      this.selectedSectionId = this.menuMap[menuId]?.sections[0]?.id;
    });
  }

  setSelectedSectionId(sectionId: string) {
    this.selectedSectionId = sectionId;
  }

  onSectionViewportEnter = (sectionId: string) => {
    this.sectionScrollList[sectionId].isInViewPort = true;
  };

  private changeSelectedSectionIfNeeded = (sectionId: string) => {
    if (this.selectedSectionId === sectionId) {
      let nextSectionId = this.sectionScrollList[sectionId].nextSectionId;
      if (!nextSectionId || !this.sectionScrollList[nextSectionId].isInViewPort) {
        nextSectionId = this.sectionScrollList[sectionId].prevSectionId;
      }
      if (nextSectionId && this.sectionScrollList[nextSectionId].isInViewPort) {
        this.setSelectedSectionId(nextSectionId);
        return true;
      }
    }
    return false;
  };

  private changeSelectedMenuIfNeeded = (menuId: string) => {
    if (this.selectedMenuId !== menuId) {
      runInAction(() => {
        this.selectedMenuId = menuId;
      });
    }
  };

  onSectionViewportLeave = (sectionId: string) => {
    runInAction(() => {
      this.sectionScrollList[sectionId].isInViewPort = false;
      const didChangeSection = this.changeSelectedSectionIfNeeded(sectionId);
      if (didChangeSection) {
        const nextMenuId = this.selectedScrollListSection.menuId;
        this.changeSelectedMenuIfNeeded(nextMenuId);
      }
    });
  };

  get selectedScrollListSection() {
    return this.sectionScrollList[this.selectedSectionId];
  }
}

export const navigationMenuState = new NavigationMenuState();
